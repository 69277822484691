import React, { useRef } from 'react';

import Title from '@components/Title';
import Field from '@components/Fields';
import Remove from '@components/Remove';

const Fluff = ({
    character,
    updateCharacter,
}) => {
    const portraitRef = useRef();
    
    return (<>
	    <input type="file" className="portrait-file" ref={ portraitRef } onChange={ (e) => {
	    	const reader = new FileReader()
	    	reader.onload = () => {
	    		updateCharacter("portrait", reader.result)
	    	}
	    	reader.readAsDataURL(e.target.files[0])
	    }} />
        <Title placeholder="Character Name" onChange={ (e) => updateCharacter("name", e.target.value) }>{ character.name }</Title>
        <div className="portrait" onClick={ () => portraitRef.current.click() } style={{ backgroundImage: 'url(' + character.portrait + ')' }} />
        <div className="textfields">
            <div className="details">
                <Field name="Species" value={ character.species } onChange={ (v) => updateCharacter("species", v) } />
                <Field name="Career" value={ character.career } onChange={ (v) => updateCharacter("career", v) } />
                <Field name="Player" value={ character.player } onChange={ (v) => updateCharacter("player", v) } />
            </div>
            <Field name="Specializations" className="specs" value={ character.specializations } onChange={ (v) => updateCharacter("specializations", v) } />
            <Field name="Motivation" className="motivation" value={ character.motivation } onChange={ (v) => updateCharacter("motivation", v) } />
            <div className="fluff">
                { character.obligation && <>
                    <Field name="Obligation" value={ character.obligation.text } onChange={ (v) => updateCharacter("obligation", {...character.obligation, text: v}) } />
                    <Field name="Obligation Points" value={ character.obligation.points } onChange={ (v) => updateCharacter("obligation", {...character.obligation, points: v}) } />
                    <Remove onClick={ (v) => updateCharacter("obligation", null) } />
                </>}
                { character.duty && <>
                    <Field name="Duty" value={ character.duty.text } onChange={ (v) => updateCharacter("duty", {...character.duty, text: v}) } />
                    <Field name="Duty Points" value={ character.duty.points } onChange={ (v) => updateCharacter("duty", {...character.duty, points: v}) } />
                    <Remove onClick={ () => updateCharacter("duty", null) } />
                </>}
                { character.morality && <>
                    <Field name="Morality" value={ character.morality.text } onChange={ (v) => updateCharacter("morality", {...character.morality, text: v}) } />
                    <Field name="Morality Points" value={ character.morality.points } onChange={ (v) => updateCharacter("morality", {...character.morality, points: v}) } />
                    <Remove onClick={ () => updateCharacter("morality", null) } />
                </>}
            </div>
        </div>
    </>);
}

export default Fluff;
