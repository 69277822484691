import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const FirestoreListener = ({
    context: Context,
    queries,
    children,
}) => {
    const [ data, setData ] = useState({
        db: firebase.firestore()
    });

    useEffect(() => {
        Object.entries(queries).forEach(([key, query]) => {
            query.onSnapshot((qs) => {
                const newData = {}
                qs.forEach((doc) => {
                    newData[doc.id] = doc.data()
                })
                setData({
                    ...data,
                    [key]: newData
                });
            })
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queries]);

    return <Context.Provider value={ data }>
        { children }
    </Context.Provider>
}

export default FirestoreListener;