import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import './remove.css';

function Remove(props) {
	return (
		<div
			className="remove"
			onClick={ () => props.onClick() }
		>
			<FontAwesomeIcon icon={ faTimesCircle } mask={['far', 'circle']} />
		</div>
	)
}

export default Remove
