import React from 'react';

import Skill from './skill';

import './skill-list.css';

function chunk(data, size) {
	const results = [[]];
	for (var e of data) {
		if (results[results.length - 1].length >= size) results.push([])
		results[results.length - 1].push(e)
	}
	return results
}

function SkillList(props) {
	if (!props || !props.skills || !props.skills.map) return null;
	
	const columns = props.columns || 1;
	
	return <>
		<h3>{ props.name }</h3>
		<table className="skills">
			<tbody>{
				chunk(props.skills, columns).map((row, i) => (<tr className="skill" key={ i }>{
					row.map((skill, j) => (
						<Skill
							key={ j }
							skill={ skill.name }
							characteristic={ skill.characteristic }
							characteristics={ props.characteristics }
							career={ skill.career }
							rank={ skill.rank }
							onChangeRank={ (rank) => props.onChange(props.skills.map((s) => s.name === skill.name ? {...s, rank: rank } : s )) }
							onChangeCareer={ (career) => props.onChange(props.skills.map((s) => s.name === skill.name ? { ...s, career } : s)) }
							onRemove={ (e) => props.onChange([...(props.skills.filter((o, idx) => i * columns + j !== idx))]) }
							custom={ props.custom ? props.custom : false }
						/>
					))
				}</tr>))
			}</tbody>
		</table>
	</>
}

export default SkillList;
