import React, { useState } from 'react';
import { useFocus } from '@hooks';

import './value.css';

function Value(props) {
	const [ edit, setEdit ] = useState(false);
	const [ setFocus, focusProps ] = useFocus(true);

	const oBlur = focusProps.onBlur
	focusProps.onBlur = (e) => {
		oBlur(e)
		setEdit(false)
	}

	return (
		<div className={ 'valueField ' +  (props.classes ? props.classes.join(' ') : '') }>
			<div
				onClick={ () => { setEdit(true); setFocus() } }
			>{
				edit ? (
					<input type="text"
						value={ props.value }
						onChange={ (e) => props.onChange(e.target.value) }
						{ ...focusProps }
					/>
				) : (
					<div className="value">{ props.value }</div>
					)
				}
				<h3>{ props.name }</h3>
			</div>
		</div>
	);
}

export default Value;
