import React, { useState } from 'react';
import { useFocus } from '@hooks';

import './pool.css';

function Pool(props) {
	const [ edit, setEdit ] = useState(false);
	const [ setFocus, focusProps ] = useFocus(true);

	const oBlur = focusProps.onBlur
	focusProps.onBlur = (e) => {
		oBlur(e)
		setEdit(false)
	}

	return (
		<div className="pool">
			<h3>{ props.name }</h3>
			<div className="left"
				onClick={ () => { setEdit('left'); setFocus() } }
			>{
					edit === 'left' ? (
						<input type="text" placeholder={ props.placeholder }
							value={ props.left }
							onChange={ (e) => props.onChangeLeft(e.target.value) }
							{ ...focusProps }
						/>
					) : (
						<div className="value">{ props.left }</div>
					)
				}
				<label>{ props.leftLabel }</label>
			</div>
			<div className="right"
				onClick={ () => { setEdit('right'); setFocus(); } }
			>{
					edit === 'right' ? (
						<input type="text" placeholder={ props.placeholder }
							value={ props.right }
							onChange={ (e) => props.onChangeRight(e.target.value) }
							{ ...focusProps }
						/>
					) : (
						<div className="value">{ props.right }</div>
					)
				}
				<label>{ props.rightLabel }</label>
			</div>
		</div>
	);
}

export default Pool;
