import React from 'react';
import Characteristic from '@components/Characteristics';
import Field from '@components/Fields';
import Hardpoints from '@components/Hardpoints';
import Remove from '@components/Remove';
import Box from '@components/TitledBox';

import './weapon.css';

function Weapon(props) {
	return (
		<div className="weapon">
			<Box
				placeholder="Name"
				title={ props.weapon.name }
				onTitleChange={ (val) => props.onChange({ ...props.weapon, name: val })}
			>
				<Characteristic label="Damage" value={ props.weapon.damage } onChange={ (val) => props.onChange({ ...props.weapon, damage: val}) } />
				<Characteristic label="Crit" value={ props.weapon.crit } onChange={ (val) => props.onChange({ ...props.weapon, crit: val}) } />
				<Characteristic label="Encumbrance" value={ props.weapon.encum } onChange={ (val) => props.onChange({ ...props.weapon, encum: val}) } />
				<Characteristic label="Hard Points" value={ props.weapon.hp } onChange={ (val) =>props.onChange({ ...props.weapon, hp: parseInt(val, 10)}) } />
				<div className="details">
					<div className="two">
						<Field value={ props.weapon.skill } name="Skill" onChange={ (val) => props.onChange({ ...props.weapon, skill: val })} />
						<Field value={ props.weapon.range } name="Range" onChange={ (val) => props.onChange({ ...props.weapon, range: val })} />
					</div>
					<Field value={ props.weapon.special } name="Special" onChange={ (val) => props.onChange({ ...props.weapon, special: val })} />
				</div>
				<Hardpoints item={ props.weapon } onChange={ props.onChange } />
				<Remove onClick={ props.onRemove } />
			</Box>
		</div>
	)
}

export default Weapon;
