import React from 'react';
import Field from '@components/Fields';
import Remove from '@components/Remove';

import './skill.css';

function Skill(props) {
	
	const char = props.characteristics.find((c) => c.name === props.characteristic)
	const charValue = char && char.rank ? char.rank : 0
	
	const yellow = Math.min(props.rank, charValue);
	const green = Math.max(props.rank, charValue) - yellow;
	
	return <>
		<td>{
			props.custom ? (
				<Field
					value={props.skill ? props.skill : ''}
					onChange={(val) => props.onChangeName(val)}
				/>
			) : (
				props.career
					? <strong onClick={() => props.onChangeCareer(false)}>{props.skill}</strong>
					: <span onClick={() => props.onChangeCareer(true)}>{props.skill}</span>
			)
			}</td>
			<td>{ props.characteristic ? props.characteristic.substr(0,3) : '' }</td>
			<td>{
				[...Array(5).keys()].map((i) => (
					<span key={ i } className={ 'pip ' + (props.rank > i ? 'checked' : '') } onClick={ () => props.rank - 1 === i ? props.onChangeRank(i) : props.onChangeRank(i + 1) } />
				))
			}</td>
			<td>{
				yellow > 0 && [...Array(yellow).keys()].map((i) => (
					<span key={ i } className="dice yellow" />
				))
			}{
				green > 0 && [...Array(green).keys()].map((i) => (
					<span key={ i } className="dice green" />
				))
			}{ props.custom ? (
				<Remove onClick={ props.onRemove } />
			) : '' }
			</td>
		</>;
}

export default Skill;
