import React, { useEffect, useState } from 'react';
import { useFocus } from '@hooks';

import './field.css';

function Field({
	name,
	value: initialValue,
	className,
	onChange,
}) {
	const [ edit, setEdit ] = useState(false);
	const [ setFocus, focusProps ] = useFocus(true);
	const [ value, setValue ] = useState('');

	useEffect(() => {
		setValue(initialValue)
	}, [initialValue, setValue]);

	const oBlur = focusProps.onBlur
	focusProps.onBlur = (e) => {
		oBlur(e);
		setEdit(false);
		onChange(value);
	}

	return (
		<span
			className={ 'field '  + className + ' ' + (onChange ? 'editable' : '') }
			onClick={ () => {
				if (onChange && !edit) {
					setEdit(true)
					setFocus()
				}
			}}
		>{
			edit ? <>
				<input type="text" 
					value={ value }
					onChange={ (e) => setValue(e.target.value) }
					{ ...focusProps }
				/>
				{ '\u00a0' }
			</> : (
				value ? value : '\u00a0'
			)
		}
			<label>{ name }</label>
		</span>
	);
}

export default Field;
