import React, { useState } from 'react';
import { useFocus } from '@hooks';

import './title.css';

function Title(props) {
	const [ edit, setEdit ] = useState(false);
	const [ setFocus, focusProps ] = useFocus(true);

	const oBlur = focusProps.onBlur
	focusProps.onBlur = (e) => {
		oBlur(e)
		setEdit(false)
	}

	if (edit) {
		return <h1>
			<input type="text" placeholder={ props.placeholder }
				value={ props.children }
				onChange={ props.onChange }
				{ ...focusProps }
			/>
		</h1>
	} else {
		return <h1 className={ props.onChange ? 'editable' : '' }
			onClick={ () => {
				if (props.onChange) {
					setEdit(true)
					setFocus()
				}
			}
		}>{ props.children ? props.children : props.placeholder }</h1>;
	}
}

export default Title;
