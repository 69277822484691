import React, { useState } from 'react';
import { useFocus } from '@hooks';

import './characteristic.css';

function Characteristic(props) {
	const [ edit, setEdit ] = useState(false);
	const [ setFocus, focusProps ] = useFocus(true);

	const oBlur = focusProps.onBlur
	focusProps.onBlur = (e) => {
		oBlur(e)
		setEdit(false)
	}

	if (edit) {
		return <div className={ 'characteristic ' + (props.force ? ' force' : '' ) + ' ' + props.className }>
			<input type="text" placeholder={ props.placeholder }
				value={ props.value }
				onChange={ (e) => props.onChange(e.target.value) }
				{ ...focusProps }
			/>
			<label>{ props.label }</label>
		</div>
	} else {
		return <div
			className={ 'characteristic ' + (props.force ? ' force ' : '' ) +  (props.onChange ? 'editable' : '') + ' ' + props.className }
			onClick={ () => {
				if (props.onChange) {
					setEdit(true)
					setFocus()
				}
			}
		}>
			<div className="value">{ props.value ? props.value : 0 }</div>
			<label>{ props.label }</label>
		</div>;
	}
}

export default Characteristic;
