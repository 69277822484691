import React from 'react';
import Characteristic from '@components/Characteristics';
import Pool from '@components/Pools';
import Hardpoints from '@components/Hardpoints';
import Remove from '@components/Remove';
import Box from '@components/TitledBox';

import './vehicle.css';

function Vehicle(props) {
	return (
		<div className="vehicle">
			<Box
				placeholder="Name"
				title={ props.vehicle.name }
				onTitleChange={ (val) => props.onChange({ ...props.vehicle, name: val })}
			>
				<div className="defences">
					<Characteristic className="fore" value={ props.vehicle.defences && props.vehicle.defences.fore } onChange={ (val) => props.onChange({ ...props.vehicle, defences: { ...props.vehicle.defences, fore: parseInt(val)}}) } />
					<Characteristic className="port" value={ props.vehicle.defences && props.vehicle.defences.port } onChange={ (val) => props.onChange({ ...props.vehicle, defences: { ...props.vehicle.defences, port: parseInt(val)}}) } />
					<Characteristic className="starboard" value={ props.vehicle.defences && props.vehicle.defences.starboard } onChange={ (val) => props.onChange({ ...props.vehicle, defences: { ...props.vehicle.defences, starboard: parseInt(val)}}) } />
					<Characteristic className="aft" value={ props.vehicle.defences && props.vehicle.defences.aft } onChange={ (val) => props.onChange({ ...props.vehicle, defences: { ...props.vehicle.defences, aft: parseInt(val)}}) } />
					<label>Defences</label>
				</div>
				<div>
					<Characteristic label="Silhouette" value={ props.vehicle.soak } onChange={ (val) => props.onChange({ ...props.vehicle, soak: parseInt(val)}) } />
					<Characteristic label="Speed" value={ props.vehicle.melee } onChange={ (val) => props.onChange({ ...props.vehicle, melee: parseInt(val)}) } />
					<Characteristic label="Handling" value={ props.vehicle.ranged } onChange={ (val) => props.onChange({ ...props.vehicle, ranged: isNaN(parseInt(val)) ? val : parseInt(val) }) } />
					<Characteristic label="Armour" value={ props.vehicle.encum } onChange={ (val) => props.onChange({ ...props.vehicle, encum: parseInt(val)}) } />
					<Pool name="Hull"
						left={ props.vehicle.hull ? props.vehicle.hull.threshold : '' }
						right={ props.vehicle.hull ? props.vehicle.hull.current : '' }
						leftLabel="Threshold"
						rightLabel="Current"
						onChangeLeft={ (v) => props.onChange({ ...props.vehicle, hull: { ...(props.vehicle.hull || {}), threshold: v }}) }
						onChangeRight={ (v) => props.onChange({ ...props.vehicle, hull: { ...(props.vehicle.hull || {}), current: v }}) }
					/>
				</div><div>
					<Characteristic label="Crew" value={ props.vehicle.crew } onChange={ (val) =>props.onChange({ ...props.vehicle, crew: parseInt(val)}) } />
					<Characteristic label="Passengers" value={ props.vehicle.passengers } onChange={ (val) =>props.onChange({ ...props.vehicle, passengers: parseInt(val)}) } />
					<Characteristic label="Cargo" value={ props.vehicle.cargo } onChange={ (val) =>props.onChange({ ...props.vehicle, cargo: parseInt(val)}) } />
					<Characteristic label="Hard Points" value={ props.vehicle.hp } onChange={ (val) =>props.onChange({ ...props.vehicle, hp: parseInt(val)}) } />
					<Pool name="System Strain"
						left={ props.vehicle.strain ? props.vehicle.strain.threshold : '' }
						right={ props.vehicle.strain ? props.vehicle.strain.current : '' }
						leftLabel="Threshold"
						rightLabel="Current"
						onChangeLeft={ (v) => props.onChange({ ...props.vehicle, strain: { ...(props.vehicle.strain || {}), threshold: v }}) }
						onChangeRight={ (v) => props.onChange({ ...props.vehicle, strain: { ...(props.vehicle.strain || {}), current: v }}) }
					/>
				</div>
				<Hardpoints item={ props.vehicle } onChange={ props.onChange } />
				<Remove onClick={ props.onRemove } />
			</Box>
		</div>
	)
}

export default Vehicle;
