import React from 'react';

import Field from '@components/Fields';
import Remove from '@components/Remove';

const Talents = ({ character, updateCharacter }) => (
	<>
		<h3>Talents</h3>
		<table className="talents">
			<thead><tr>
				<th>Name</th>
				<th>Rank</th>
				<th>Desc.</th>
				<th></th>
			</tr></thead>
			<tbody>{
				character.talents && character.talents.map((talent, i) => (
					<tr key={ i }>
						<td>
							<Field
								value={ talent.name }
								onChange={ (value) => updateCharacter("talents", character.talents.map((t, idx) => idx === i ? { ...t, name:value } : t)) }
							/>
						</td><td>
							<Field
								value={ talent.rank }
								onChange={ (value) => updateCharacter("talents", character.talents.map((t, idx) => idx === i ? { ...t, rank:value } : t)) }
							/>
						</td><td>
							<Field
								value={ talent.desc }
								onChange={ (value) => updateCharacter("talents", character.talents.map((t, idx) => idx === i ? { ...t, desc:value } : t)) }
							/>
						</td><td>
							<Remove onClick={ () => updateCharacter("talents", character.talents.filter((_, idx) => idx !== i)) } />
						</td>
					</tr>
				))
			}</tbody>
		</table>
	</>
);

export default Talents;