import React from 'react';
import Field from '@components/Fields';

function Hardpoints(props) {
	return (
		props.item.hp && props.item.hp > 0 ? (
			<div className="hardpoints">{
				[...Array(props.item.hp ? props.item.hp : 0).keys()].map((i) => (
					<Field
						key={ i }
						name={ 'Hard Point ' + (i + 1) }
						value={ props.item.hardpoints ? props.item.hardpoints[i] : '' }
						onChange={ (val) => {
							const hp = (props.item.hardpoints ? props.item.hardpoints : []);
							hp[i] = val;
							props.onChange({
								...props.item,
								hardpoints: hp,
							})
						}}
					/>
				))
			}</div>
		) : '' 
	)
}

export default Hardpoints;
