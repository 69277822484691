import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import React, { useState } from 'react';
import Sheet from '@components/Sheet';
import FirestoreContext from '@contexts/firestore';
import { FirestoreListener } from '@components/Firestore';
import firebaseConfig from '@credentials/firebase';

import Controls from './controls';

firebase.initializeApp(firebaseConfig)

function SWRPG() {
	const [character, setCharacter] = useState(0);
	const [user, setUser] = useState();

	firebase.auth().onAuthStateChanged((u) => {
		if (u) {
			setUser(u);
		} else {
			setUser(null);
		}
	});

	return user ? (
		<FirestoreListener
			queries={{
		  		characters: firebase.firestore().collection('characters').where('owner', '==', firebase.auth().currentUser.uid)
			}}
			context={ FirestoreContext }
	  	>
			<FirestoreContext.Consumer>{
				({ characters }) => {
					const firstChar = characters && Object.keys(characters).length > 0 ? Object.keys(characters)[0] : ''
					return (<>
						<Sheet
							key={ character || firstChar }
							characterId={ character || firstChar }
						/>
						<Controls
							character={ characters && characters[character || firstChar] }
							characters={ characters }
							characterId={ character || firstChar }
							onCharacterChanged={ (c) => {
								setCharacter(c);
							}}
						/>
					</>)
				}
			}</FirestoreContext.Consumer>
	  	</FirestoreListener>	
	): (
		<button onClick={ () => {
			const provider = new firebase.auth.GoogleAuthProvider();
			firebase.auth().signInWithPopup(provider)
		}}>	  
			Sign In
		</button>
	)
}

export default SWRPG;
