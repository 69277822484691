import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import defaultCharacter from './character.json';

const Controls = ({
    character,
    characters,
    characterId,
    onCharacterChanged,
}) => {
    if (!character) return null;

	const updateCharacter = (key, value) => {
		firebase.firestore().collection('characters').doc(characterId).set({
			...character,
			[key]: value,
		});
	}

    return (
        <div className="controls">
            <select
                value={ characterId }
                onChange={ (e) => onCharacterChanged(e.target.value) }
            >{
                characters && Object.entries(characters).map(([ id, c ]) => (
                    <option key={ id } value={ id } >{ c.name }</option>
                ))
            }</select>
            <button onClick={ () => {
                firebase.firestore().collection('characters').add({
                    ...defaultCharacter,
                    owner: firebase.auth().currentUser.uid
                })
            }}>Add Character</button>
            <button onClick={ () => {
                if (window.confirm('Delete "' + character.name + '"')) {
                    firebase.firestore().collection('characters').doc(characterId).delete();
                }
            }}>Delete Character</button>
            <select
                value=""
                onChange={ (e) => (
                    {
                        obligation: () => updateCharacter("obligation", {}),
                        duty: () => updateCharacter("duty", {}),
                        morality: () => updateCharacter("morality", {}),
                        customskill: () => updateCharacter("custom", [ ...character.custom, {}]),
                        weapon: () => updateCharacter("weapons", [ ...character.weapons, {}]),
                        armour: () => updateCharacter("armour", [ ...character.armour, {}]),
                        talents: () => updateCharacter("talents", [ ...character.talents, {}]),
                        gear: () => updateCharacter("gear", [ ...character.gear, {}]),
                        vehicle: () => updateCharacter("vehicles", [ ...character.vehicles, {}]),
                        forcepower: () => updateCharacter("forcePowers", [ ...character.forcePowers, {}]),
                    }[e.target.value]()
                )}
            >
                <option value="">Add Character Element...</option>
                { character.obligation ? null : <option value="obligation">Add Obligation</option> }
                { character.duty ? null : <option value="duty">Add Duty</option> }
                { character.morality ? null : <option value="morality">Add Morality</option> }
                <option value="customskill">Add Custom Skill</option>
                <option value="weapon">Add Weapon</option>
                <option value="armour">Add Armour</option>
                <option value="talents">Add Talent</option>
                <option value="gear">Add Gear</option>
                <option value="vehicle">Add Vehicle</option>
                <option value="forcepower">Add Force Power</option>
            </select>
        </div>
    );
}

export default Controls