import React from 'react';
import Box from '@components/TitledBox';
import Characteristic from '@components/Characteristics';
import Field from '@components/Fields';
import Hardpoints from '@components/Hardpoints';
import Remove from '@components/Remove';

import './armour.css';

const Armour = (props) => (
	<div className="armour">
        <Box
            placeholder="Name"
            title={ props.armour.name }
            onTitleChange={ (val) => props.onChange({ ...props.armour, name: val })}
        >
			<div className="details">
				<Field value={ props.armour.special } name="Special" onChange={ (val) => props.onChange({ ...props.armour, special: val })} />
			</div>
			<Characteristic label="Soak" value={ props.armour.soak } onChange={ (val) => props.onChange({ ...props.armour, soak: parseInt(val)}) } />
			<Characteristic label="Melee Defence" value={ props.armour.melee } onChange={ (val) => props.onChange({ ...props.armour, melee: parseInt(val)}) } />
			<Characteristic label="Ranged Defence" value={ props.armour.ranged } onChange={ (val) => props.onChange({ ...props.armour, ranged: parseInt(val)}) } />
			<Characteristic label="Encumbrance" value={ props.armour.encum } onChange={ (val) => props.onChange({ ...props.armour, encum: parseInt(val)}) } />
			<Characteristic label="Hard Points" value={ props.armour.hp } onChange={ (val) =>props.onChange({ ...props.armour, hp: parseInt(val)}) } />
			<Hardpoints item={ props.armour } onChange={ props.onChange } />
			<Remove onClick={ props.onRemove } />
        </Box>
	</div>
);

export default Armour;
