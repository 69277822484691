import React from 'react';

import Field from '@components/Fields';
import Remove from '@components/Remove';

const Gear = ({ character, updateCharacter }) => (
	<>
		<h3>Gear</h3>
		<table className="talents">
			<thead><tr>
				<th>Name</th>
				<th>Encum.</th>
				<th>Desc.</th>
				<th></th>
			</tr></thead>
			<tbody>{
				character.gear && character.gear.map((item, i) => (
					<tr key={ item + i }>
						<td>
							<Field
								value={ item.name }
								onChange={ (v) => updateCharacter("gear", character.gear.map((t, idx) => idx === i ? { ...t, name:v } : t)) }
							/>
						</td>
						<td>
							<Field
								value={ item.encum }
								onChange={ (value) => updateCharacter("gear", character.gear.map((t, idx) => idx === i ? { ...t, encum:value } : t)) }
							/>
						</td>
						<td>
							<Field
								value={ item.desc }
								onChange={ (value) => updateCharacter("gear", character.gear.map((t, idx) => idx === i ? { ...t, desc:value } : t)) }
							/>
						</td><td>
							<Remove onClick={ () => updateCharacter("gear", character.gear.filter((_, idx) => idx !== i)) } />
						</td>
					</tr>
				))
			}</tbody>
		</table>
	</>
);

export default Gear;