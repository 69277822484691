import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import FirestoreContext from '@contexts/firestore';
import Characteristic from '@components/Characteristics';
import { SkillList } from '@components/Skills';
import Pool from '@components/Pools';
import Weapon from '@components/Weapon';
import Armour from '@components/Armour';
import Vehicle from '@components/Vehicle';
import Value from '@components/Value';
import ForcePower from '@components/ForcePower';

import Talents from './talents';
import Gear from './gear';
import Fluff from './fluff';
import './sheet.css';

function Sheet({
	characterId,
}) {
	const [ character, setCharacter ] = useState({});
	const { characters } = useContext(FirestoreContext);

	const updateCharacter = (key, value) => {
		firebase.firestore().collection('characters').doc(characterId).set({
			...character,
			[key]: value,
		});
	}

	useEffect(() => {
		if (characters && characters[characterId]) {
			setCharacter(characters[characterId]);
		}
	}, [characters, characterId]);
	
	console.log(character);
		
	return <>
		<div className="sheet">
			<Fluff
				character={ character }
				updateCharacter={ updateCharacter }
			/>
			<div className="characteristics">{
				character.characteristics && character.characteristics.map((characteristic) => (
					<Characteristic
						key={ characteristic.name }
						label={ characteristic.name }
						value={ characteristic.rank }
						onChange={ (rank) => updateCharacter(
							"characteristics",
							character.characteristics.map((c) => c.name === characteristic.name ? {...c, rank: rank } : c )
						)}
					/>
				))
				}<Characteristic label="Force Rank" value={ character.force } onChange={ (v) => updateCharacter("force", v) } force={ true } />
			</div>
			<div>
				<SkillList name="General" characteristics={ character.characteristics } skills={ character.skills } onChange={ (v) => updateCharacter("skills",v)  } columns="2" />
				{ character.custom?.length > 0 ? (
					<SkillList name="Custom" characteristics={ character.characteristics } skills={ character.custom } onChange={ (v) => updateCharacter("custom", v) } columns="2" custom />
				) : '' }
				<div className="column-2">
					<SkillList name="Combat" characteristics={ character.characteristics } skills={ character.combat } onChange={ (v) => updateCharacter("combat", v) } />
				</div>
				<div className="column-2">
					<SkillList name="Knowledge" characteristics={ character.characteristics } skills={ character.knowledge } onChange={ (v) => updateCharacter("knowledge", v) } />
				</div>
			</div>
			<div className="pools">
				<Value name="Credits" classes={ [ 'small' ] }
					value={ character.credits }
					onChange={ (v) => updateCharacter("credits", v) }
				/>
				<Pool name="Experience"
					left={ character.xp?.total }
					right={ character.xp?.spent }
					leftLabel="Total"
					rightLabel="Spent"
					onChangeLeft={ (v) => updateCharacter("xp", {...character.xp, total: v}) }
					onChangeRight={ (v) => updateCharacter("xp", {...character.xp, spent: v}) }
				/>
				<Pool name="Encumbrance"
					left={ character.encumbrance?.threshold }
					right={ character.encumbrance?.current }
					leftLabel="Threshold"
					rightLabel="Current"
					onChangeLeft={ (v) => updateCharacter("encumbrance", {...character.encumbrance, threshold: v}) }
					onChangeRight={ (v) => updateCharacter("encumbrance", {...character.encumbrance, current: v}) }
				/>
				<Value name="Force Commit"
					value={ character.forceCommitted }
					onChange={ (v) => updateCharacter("forceCommitted", v) }
				/>			
			</div>
		</div>
		<div className="sheet">
		<div>
			<div className="column-2">
				<Talents character={ character } updateCharacter={ updateCharacter } />
			</div>
			<div className="column-2">
				<Gear character={ character } updateCharacter={ updateCharacter } />
			</div>
			<div className="weapon-list">{ character.weapons && character.weapons.map((w, i) => (
				<Weapon
					key={ i }
					weapon={ w }
					onChange={ (w) => {
						updateCharacter("weapons", character.weapons.map((w2, idx) => idx === i ? w : w2))
					}}
					onRemove={ () => {
						updateCharacter("weapons", character.weapons.filter((o, idx) => idx !== i))
					}}
				/>
			))}</div>
			<div className="armour-list">{ character.armour && character.armour.map((a, i) => (
				<Armour
					key={ i }
					armour={ a }
					onChange={ (a) => {
						updateCharacter("armour", character.armour.map((a2, idx) => idx === i ? a : a2))
					}}
					onRemove={ () => {
						updateCharacter("armour", character.armour.filter((o, idx) => idx !== i))
					}}
				/>
			))}</div>
			<div className="vehicle-list">{ character.vehicles && character.vehicles.map((v, i) => (
				<Vehicle
					key={ i }
					vehicle={ v }
					onChange={ (v) => {
						updateCharacter("vehicles", character.vehicles.map((v2, idx) => idx === i ? v : v2))
					}}
					onRemove={ () => {
						updateCharacter("vehicles", character.vehicles.filter((o, idx) => idx !== i))
					}}
				/>
			))}</div>
			</div>
			<div className="forcepower-list">{ character.forcePowers && character.forcePowers.map((p, i) => (
				<ForcePower
					key={ i }
					power={ p }
					onChange={ (p) => {
						updateCharacter("forcePowers", character.forcePowers.map((p2, idx) => idx === i ? p : p2))
					}}
					onRemove={ () => {
						updateCharacter("forcePowers", character.forcePowers.filter((o, idx) => idx !== i))
					}}
				/>
			))}</div>
			<div className="pools">
				<Pool name="Wounds"
					left={ character.wounds?.threshold }
					right={ character.wounds?.current }
					leftLabel="Threshold"
					rightLabel="Current"
					onChangeLeft={ (v) => updateCharacter("wounds", { ...character.wounds, threshold: v}) }
					onChangeRight={ (v) => updateCharacter("wounds", { ...character.wounds, current: v}) }
				/>
				<Pool name="Strain"
					left={ character.strain?.threshold }
					right={ character.strain?.current }
					leftLabel="Threshold"
					rightLabel="Current"
					onChangeLeft={ (v) => updateCharacter("strain", { ...character.strain, threshold: v}) }
					onChangeRight={ (v) => updateCharacter("strain", { ...character.strain, current: v}) }
				/>
				<Pool name="Defence"
					left={ character.defence?.ranged }
					right={ character.defence?.melee }
					leftLabel="Ranged"
					rightLabel="Melee"
					onChangeLeft={ (v) => updateCharacter("defence", { ...character.defence, ranged: v}) }
					onChangeRight={ (v) => updateCharacter("defence", { ...character.defence, melee: v}) }
				/>
				<Pool name="Soak"
					left={ character.soak?.natural }
					right={ character.soak?.armoured }
					leftLabel="Natural"
					rightLabel="Armoured"
					onChangeLeft={ (v) => updateCharacter("soak", { ...character.soak, natural: v}) }
					onChangeRight={ (v) => updateCharacter("soak", { ...character.soak, armoured: v}) }
				/>
			</div>
		</div>
	</>
}

export default Sheet;
