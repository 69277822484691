import React from 'react';

import Field from '@components/Fields';

import './box.css';

const Box = ({
    title,
    onTitleChange,
    placeholder,
    children
}) => (<div className="box">
    <Field
        value={ title }
        name={ placeholder }
        onChange={ onTitleChange }
        className="name"
    />
    <div className="inner">{ children }</div>
</div>);

export default Box;