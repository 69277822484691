import { useState } from 'react';

//From: https://stackoverflow.com/questions/43145549/how-react-programmatically-focus-input/54512101
const useFocus = (initialFocus = false, id = "") => {
	const [focus, setFocus] = useState(initialFocus)
	const setFocusWithTrueDefault = (param) => setFocus(param === false ? false : true)
	return ([
		setFocusWithTrueDefault, {
			autoFocus: focus,
			key: `${id}${focus}`,
			onFocus: (e) => { setFocus(true); e.target.select() },
			onBlur: () => setFocus(false),
		},
	])
}

const usePersistentState = (ns, index, name, initial) => {
	const data = localStorage[ns] ? JSON.parse(localStorage[ns]) : []
	const res = useState(data[index] && data[index][name] ? data[index][name] : initial);
	const setState = res[1]
	
	res[1] = (v) => {
		setState(v)
		const persisted  = localStorage[ns] ? JSON.parse(localStorage[ns]) : [];
		if (!persisted[index]) persisted[index] = {};
		persisted[index] = { ...persisted[index], [name]:v };
		localStorage.setItem(ns, JSON.stringify(persisted))
	}
	return res
}

export { useFocus, usePersistentState };
