import React from 'react';
import Characteristic from '@components/Characteristics';
import Field from '@components/Fields';
import Remove from '@components/Remove';

import './forcepower.css';
import Box from '../TitledBox';

function ForcePower(props) {
	return (
		<div className="forcepower">
			<Box
			 	placeholder="Name"
				title={ props.power.name }
				onTitleChange={ (val) => props.onChange({ ...props.power, name: val })}
			>
				<Remove onClick={ props.onRemove } />				
				<Characteristic force label="Range" value={ props.power.range } onChange={ (val) => props.onChange({ ...props.power, range: parseInt(val)}) } />
				<Characteristic force label="Strength" value={ props.power.strength } onChange={ (val) => props.onChange({ ...props.power, strength: parseInt(val)}) } />
				<Characteristic force label="Duration" value={ props.power.duration } onChange={ (val) => props.onChange({ ...props.power, duration: parseInt(val)}) } />
				<Characteristic force label="Magnitude" value={ props.power.magnitude } onChange={ (val) => props.onChange({ ...props.power, magnitude: parseInt(val)}) } />
				<Characteristic force label="Control" value={ props.power.control } onChange={ (val) => props.onChange({ ...props.power, control: parseInt(val)}) } />
				<Characteristic force label="Mastery" value={ props.power.mastery } onChange={ (val) => props.onChange({ ...props.power, mastery: parseInt(val)}) } />
				<div className="text">
					<Field value={ props.power.effect } name="Basic Effect" onChange={ (val) => props.onChange({ ...props.power, effect: val })} />
					{ props.power.range > 0 && <Field value={ props.power.rangeEffect } name="Range" onChange={ (val) => props.onChange({ ...props.power, rangeEffect: val })} /> }
				</div>
			</Box>
		</div>
	)
}

export default ForcePower;
